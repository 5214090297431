import React, { useEffect } from "react";
import { convertTime } from "../../utils";
import { useUser } from "context/useContext";
import { VIP_VALUES } from "../../constants";

const LongJobFeedback = ({ longJobCost, isTechnicianSubmitFeedback, job, handleLinkClick }) => {
  const { user } = useUser();

  return (
    <>
      <tr>
        <th>Total Job Cost</th>
        <td>
          <span className="job-value">
            <>
              {" "}
              $ {longJobCost && longJobCost.totalCost}
              {isTechnicianSubmitFeedback && <> <small> (Pending Review) </small></>}
            </>
          </span>
        </td>
      </tr>
      {(user && user.customer && !user.customer.vipVote.voted  && !user.customer.subscription && longJobCost && longJobCost.totalCost > 0 && !user?.businessName) ? (
        <tr>
            <th style={{ 
              color: "#01d4d5",
            }}> Save {VIP_VALUES.meetingOffer}%! </th>
          <td>
            Your job could be
            <span className="job-value offer">
              {" "}
              ${longJobCost && (longJobCost.totalCost - (longJobCost.totalCost * (VIP_VALUES.meetingOffer / 100))).toFixed(2)}
            </span>
            {" "}
            <a onClick={handleLinkClick} className="vip-offer-link">
              Join Now & Save 20% of this job
            </a>
          </td>
        </tr>
      ) : null}
      {job.total_subscription_seconds > 0 && (
        <tr>
          <th>Subscription time deducted</th>
          <td>
            <span className="job-value">
              <>{convertTime(job.total_subscription_seconds)}</>
            </span>
          </td>
        </tr>
      )}
    </>
  );
};

export default LongJobFeedback;
